@font-face {
    font-family:BuenosAires-Regular;
    src: url(../fonts/BuenosAires-Regular.ttf);
  }
  @font-face {
    font-family:Opensans;
    src: url(../fonts/OpenSans-Regular.ttf);
  }

.statuschildpage {
  margin-bottom: 100px !important;
}

body::after {
  /*hide images*/
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url('../image/Shape-bell.svg') url('../image/circle-check-solid.svg') url('../image/caution_soldi.svg') url('../image/circle-minus-solid.svg') url('../image/configure-settings-solid.svg') url('../image/info.svg');
  /*load images*/
}

app-status {
    font-family: Opensans;
}

body::-webkit-scrollbar,
.notification-bar .tab-content::-webkit-scrollbar,
.mat-dialog-container::-webkit-scrollbar {
  width: 10px !important;
  font-family: Opensans !important;
}

body::-webkit-scrollbar-track,
.notification-bar .tab-content::-webkit-scrollbar-track,
.mat-dialog-container::-webkit-scrollbar-track {
  background-color: #e4e4e4 !important;
  border-radius: 4px !important;
}

body::-webkit-scrollbar-thumb,
.notification-bar .tab-content::-webkit-scrollbar-thumb,
.mat-dialog-container::-webkit-scrollbar-thumb
{
  border-radius: 100px !important;
  background-color: #8E9BA7;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5) !important;
}

/* app-status{
    color: #0f2771;
} */

  app-status a {
    color: #0f2771;
    text-decoration: none;
  }

app-status a:hover {
  text-decoration: none;
  color: #0f2771;
}

.bg-lightblue{
 background: #eff4f8;   
}
.bg-lightblack {
  background-color: #C6CDD2;
}

.f13{
    font-size: 13px;
}

.f18{
    font-size:20px;
}

.f25{
    font-size: 25px;
}

.nav-pills .nav-link {
    /* background-color: #f6f6f6; */
    margin-left: 2px;
    padding: 7px 10px;
    font-size: 15px;
    color: #000000;
    border-bottom:3px solid transparent;
    border-radius: 0px;
}

#pills-tab .nav-link {
  line-height: 12px;
}

#pills-sidebar-tab .nav-link {
  line-height: 9px;
}

.nav-pills .nav-link.active {
    background-color: transparent !important;
    font-weight: bold;
    color: #0f2771;
}

.border-bstyle{
    background: transparent;
    padding:1.3pt;
    width:100%;
    border-top-left-radius:100px;border-top-right-radius:100px;
    position:relative;
    top:11px;
}

.nav-pills .nav-link.active .border-bstyle{
    background: #0024A3 !important;
    border-radius: 0px 0px 100px 100px;
    transform: rotate(180deg);
}

.mw-27{
    min-width: 27px;
}

.logo-text{
    font-family: BuenosAires-Regular;
color: #000000;
font-size: 24px;
line-height:32px;
}

/* .table-responsive{
    overflow-x:unset !important;
} */


/* #sticky {
    background-color: #ffffff;
}

#sticky.stick {
    position: fixed;
    border-top: var(--main-color) 5px solid;
    top: 70;
    z-index: 10;
    width: 82.7%;
    padding: 0px !important;
    min-width: 1115px;
}

.stick-thead thead .hfirst {
    width: 20%;
    min-width: 170px;
}

.stick-thead thead .datatd {
    width: 10.2%;
} */

/* icons */
.bg-bell-noti{
    background-image: url('../image/Shape.svg') !important;
    background-position-x: right !important;
    position:absolute;
    background-repeat: no-repeat;
    padding:12px;
    top: -27px;
    right: 0px;
    cursor: pointer !important;
}
.bg-bell-noti:hover{
    background-image: url('../image/Shape-bell.svg') !important;
    cursor:pointer;
    cursor: pointer !important;
}
.bg-bell-noti:active{
    background-image: url('../image/Shape-bell.svg') !important;
}
/* .bg-bell-noti:focus{
    background-image: url('../image/Shape-bell.svg');
    cursor:pointer;
    cursor: pointer !important;
} */

.operation{
    background-image: url('../image/circle-check.svg');
    background-repeat: no-repeat;
    background-position-x:50%;
    background-position-y:52%;
    padding: 12px;
    /*cursor: pointer !important;*/
    cursor: pointer;
}
.operation:hover{
    background-image: url('../image/circle-check-solid.svg');
    background-repeat: no-repeat;
    background-position-x:50%;
    background-position-y:52%;
    cursor: pointer !important;
}

.outage{
    background-image: url('../image/caution.svg');
    background-repeat: no-repeat;
    background-position-x:50%;
    background-position-y:52%;
    padding: 12px;
    cursor: pointer !important;
}
.outage:hover{
    background-image: url('../image/caution_soldi.svg');
    background-repeat: no-repeat;
    background-position-x:50%;
    background-position-y:52%;
    cursor: pointer !important;
}

.major-outage{
    background-image: url('../image/cross.svg');
    background-repeat: no-repeat;
    background-position-x:50%;
    background-position-y:52%;
    padding: 12px;
    cursor: pointer !important;
}



.major-outage:hover {
  background-image: url('../image/cross-fill.svg');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 52%;
  cursor: pointer !important;
}

.degradation {
  background-image: url('../image/circle-minus.svg');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 52%;
  padding: 12px;
  cursor: pointer !important;
}

  .degradation:hover {
    background-image: url('../image/circle-minus-solid.svg');
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 52%;
    cursor: pointer !important;
  }

.setting{
    background-image: url('../image/configure-settings.svg');
    background-repeat: no-repeat;
    background-position-x:50%;
    background-position-y:52%;
    padding: 12px;
    cursor: pointer !important;
}
.setting:hover{
    background-image: url('../image/configure-settings-solid.svg');
    background-repeat: no-repeat;
    background-position-x:50%;
    background-position-y:52%;
    cursor: pointer !important;
}

.non-regional{
    background-image: url('../image/info.jpeg');
    background-repeat: no-repeat;
    background-position-x:50%;
    background-position-y:52%;
    padding: 10px;
    background-size:80%;
    cursor: pointer !important;
}
.non-regional:hover{
    background-image: url('../image/info.svg');
    background-repeat: no-repeat;
    background-position-x:50%;
    background-position-y:52%;
    cursor: pointer !important;
}

/* alert-yellow */
.alert-yellow{
    position: absolute; z-index: 10000000000000;
    background-color:#fed43d;
    width: 50%;
    margin: 0 auto;
    top:13%;
    left:25%;
}
.alert-yellow p{
    word-break:break-all;
    margin-bottom: 0px;
}

/*  */
.tooltipexa {
    position: relative;
    display: inline-block;cursor: pointer !important;
}
  
.tooltipexa .tooltiptext {
    font-size: 12px;
visibility: hidden;
/* width:100%; */
background-color: #304654;
color: #fff;
text-align: center;
border-radius:6px;
padding:10px;
position: absolute;
z-index: 1;
top:100%;
left:0%;
margin-left:0px;
margin-top:20%;
opacity: 0;
transition: opacity 0.3s;
}
  
.tooltipexa:hover .tooltiptext {
visibility: visible;
opacity: 1;
cursor: pointer !important;
}

.input-group-text {
    background-color: #ffffff !important;
    border-top: 1px solid #ced4da !important;
    border-bottom: 1px solid #ffffff !important;
    border-left: 1px solid #ced4da !important;
    border-right: 0px solid #ffffff !important;
  
    position: absolute;
    z-index: 100;
    top: 0;
    padding: 8px 10px;
    left:-2px;
  }
  .input-group .form-control{
      padding-left:40px;
  }

.notification-bar {
  position: absolute;
  width: 450px;
  height: calc(100% - 65px);
  right: 0px;
  top: 65px;
  z-index: 10000;
}
  
  .notification-bar .tab-content{
      height:88%;
      overflow-y: scroll;
  }

  .notification-bar .nav-link{
      font-size: 14px !important;
  }
  .notification-bar .data-description{
      font-size: 14px;
  }

  .top-date img{
      margin-top: -2px;
  }

  .mobile-menu-links{
      display: none;
  }

  .notification-bell{
    position: absolute;
    top:35px;
    margin: auto;
    /* right:1%; */
   }

   .notification-bell-Admin{
    position: absolute;
    top:47px;
    margin: auto;
    /* right:1%; */
   }
   
   /* secont page code css */
    .logoi1 {
        width: 20%;
    }
    .right-sidetext-content{
        color: #000000;
    }
    .right-sidetext-content a{
        font-size: 17px;color: #000000;
    }
    .right-sidetext-content a:hover{
        font-size: 17px;color: #006699;
    }
    .right-sidetext-content label{
        font-size:17px;
    }

   @media only all and (max-width: 600px) {

    .top-date{
        font-size: 12px !important;
    }


    .notification-bar{
        position: absolute; width:300px; height:88%; right:0px; top:67px; z-index:10000;
    }

   .notification-bell{
       position: absolute;
       top:5%;
       right:90px;
   }
    .menu-links{
        display: none;
    }

    .mobile-menu-links{
        display: block;
    }
    .mobile-menu-links button{
        border: 1px solid #888;
        color: #888;
        margin-bottom: 10px;
        position: absolute;
        top:2.5%;
        right:20px;
    }
    
    .login-container{
        text-align: center !important;
    }
    .alert-yellow{
        width:80%;
        left:11%;
    }
    .signs-align{
        text-align: center !important;
        margin-top:-20px !important;
    }
}

.fw-semi-bold {
    font-weight: 600;
}

.f12{
    font-size:12px;
}

app-status td {
  padding: 0rem 0.5rem;
}

app-status .bg-lightblack td {
  padding: 0.5rem 0.5rem;
}


.dfirstNew {
  width: 15%;
  max-width:100px;
  word-wrap: break-word;
}

.dcolnew {
    width:9%;
    max-width:40px;
}


@media only all and (max-width: 600px) {
  .dcolnew {
    width: 5%;
  }

  .dfirstNew {
    overflow: hidden;
  }
}

.cursor-pointer {
  cursor:pointer;
}

.text-modal-date {
  color: #657888;
}


.modal-sticky-footer {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
}

.model-sticky-body {
  min-height: 50vh;
}

.o-wbreak {
  overflow-wrap: break-word;
}
