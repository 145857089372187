
@font-face {
    font-family: fontsnew;
    src: url("/assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: BuenosAires-Regular;
  src: url(../fonts/BuenosAires-Regular.ttf);
}

@font-face {
  font-family: Fontsbody;
  src: url(../fonts/OpenSans-Regular.ttf);
}

:root {
    --red: #e53535;
    --cyan: #45a9c5;
    --orange: #ea8335;
    --light-blue: #243a5e;
    --black: #333333;
    --olive-green: #17252a;
    --sky-blue: #4ec7e4;
    --low-red: #f24e66;
    /* --bar-color: var(--olive-green); */
    /* --main-color: var(--cyan); */
    /* --main-bg-color: var(--red); */
    --bar-color: var(--light-black);
    --main-color: var(--red);
    --main-bg-color: var(--light-blue);
    --main-primary2: #3598dc;
}

app-main,app-admin {
    font-family: Fontsbody !important;
    background-color: #f9f9f9;
    /* background-image: url('../image/bgabout.jpg');
    background-attachment: fixed; */
}

  app-main a, app-admin a {
    text-decoration: none;
  }


.f12 {
    font-size: 12px;
}

.f16 {
    font-size: 16px;
}

.f14 {
    font-size: 14px;
}

.f18 {
    font-size: 18px;
}

.f24 {
    font-size: 24px;
}

app-admin .top-bar, app-main .top-bar {
  background: #efefef;
}

.bg-yellow {
    background-color: #e5b539;
}

app-main .dropdown-toggle::after, app-admin .dropdown-toggle::after {
  display: none;
}



app-main .bottom-menu a {
    color: #000000;
}

  app-main .bottom-menu a:hover {
    color: var(--red);
  }



app-main .nav-pills .nav-link {
    /* background-color: #f6f6f6; */
    margin-left: 2px;
    padding: 7px 10px;
    font-size: 12px;
    color: #000000;
    border-bottom: 2px solid transparent;
}

  app-main  .nav-pills .nav-link.active {
    background-color: transparent !important;
    font-weight: bold;
    color: #000000;
    border-bottom: 2px solid #45a9c5;
  }




/* side-bar-menu */

/* stick top bar code */

app-main #sticky {
  background-color: #ffffff;
  /* color: #fff; */
}

  app-main #sticky.stick {
    position: fixed;
    border-top: var(--main-color) 5px solid;
    top: 70;
    z-index: 10;
    width: 82.7%;
    padding: 0px !important;
    min-width: 1115px;
  }

app-main .stick-thead thead .hfirst {
  width: 20%;
  min-width: 170px;
}

app-main .stick-thead thead .datatd {
  width: 10.2%;
}

app-main .table thead th,
app-main .table thead td,
app-admin .table thead th,
app-admin .table thead td {
  min-width: 10.2%;
}


/* second page demo css */

app-main .logoi1, app-admin .logoi1 {
  width: 7%;
  max-width: 40px;
}

app-main .table thead
app-admin .table thead,
app-main .table th,
app-admin .table th {
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  padding: 20px 2px !important;
  font-weight: normal;
  border: #dedede solid 1px !important;
}

app-main .table tr,
app-main .table td,
app-admin .table tr,
app-admin .table td {
  font-size: 12px;
  padding: 18px 25px !important;
  vertical-align: middle;
}

app-main .table-bar, app-admin .table-bar {
  background-color: #dedede;
  font-size: 14px;
}

app-main .table-newpage tr:hover,
app-admin .table-newpage tr:hover {
  cursor: pointer;
  background-color: #efefef;
}

app-main .top-bar-new, app-admin .top-bar-new {
  background: #ffffff;
  border-bottom: #dedede solid 1px;
  display: none;
}

  app-main .top-bar-new .container h4.
  app-admin .top-bar-new .container h4 {
    font-weight: 900 !important;
  }

.section-first {
    margin-top: 30px;
    /* padding:20px; */
}

.section-first p {
    font-size: 12px;
}

.section-two {
    margin-top: 20px;
}


/* .section-two .dropdown-menu{
    padding: 20px;
} */

.bitimgdata {
    width: 40px;
    margin-right: 7px;
}

.bitimgdata_newpage {
    width: 25px;
}

.bg-success {
    background-color: rgb(85, 163, 98) !important;
}

.bg-warning {
    background-color: rgba(214, 127, 60, 1) !important;
}

.bg-danger {
    background-color: rgba(205, 74, 69, 1) !important;
}

.bg-primary {
    background-color: rgba(0, 120, 212, 1) !important;
}

.bottom-bar label span {
    padding: 2px;
}

.bottom-bar label {
    font-size: 16px;
}

.dfirst {
    width: 20%;
}

.dcol {
    width: 7%;
    min-width: 60px !important;
}

@media only all and (min-width: 1440px) {
  app-admin .sticky-footer, app-main .sticky-footer {
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: 100%;
  }

   /* .container-fluid {
        padding-left: 130px;
        padding-right: 130px;
    }*/

   app-main #sticky.stick {
    position: fixed;
    border-top: var(--main-color) 5px solid;
    top: 70;
    z-index: 10;
    width: 68%;
    padding: 0px !important;
  }

  app-main .stick-thead thead .hfirst {
    width: 20%;
  }

  app-main .table thead,
  app-main .table th,
  app-admin .table thead,
  app-admin .table th {
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    font-size: 10px;
    /* font-weight: normal; */
    padding: 20px 2px !important;
  }
}

@media only all and (max-width: 1200px) and (min-width: 800px) {
    app-main #sticky.stick {
        position: fixed;
        border-top: var(--main-color) 5px solid;
        top: 70;
        z-index: 100;
        width: 84%;
        padding: 0px !important;
        min-width: 70%;
    }
}

@media only all and (max-width: 600px) {
    app-main .top-bar-for-mobile {
        margin-top: 20% !important;
        font-size: 12px;
    }
    .sidebar,
    .sidebaricons {
        display: none;
    }

    app-main .logo {
        margin-top: 1rem!important;
    }

    .main {
        margin-left: 0px !important;
    }

    .top-bar-new {
        display: block;
    }
    .section-first .table-width-side-heading {
        min-width: 260px !important;
    }
    .section-first .table-data-width {
        min-width: 150px !important;
        text-align: left !important;
    }
    .navbar-nav .dropdown-menu {
        position: absolute;
        margin-left: -70px;
    }
    .nav-first {
        margin-top: 10px;
    }
    .logoi1 {
        width: 20%;
    }
    .table-width-side-heading {
        min-width: 70px !important;
    }
    .table-data-width {
        min-width: 70px !important;
        text-align: center;
    }
    app-main #sticky.stick {
        position: relative;
        border-top: var(--main-color) 5px solid;
        top: 70;
        z-index: 10;
        width: 92.8%;
        padding: 0px !important;
        min-width: 500px;
    }
}

.sidebaricons {
    height: 100%;
    width: 50px;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: #243a5e;
    overflow-x: hidden;
    padding-top: 6px;
}

.sidebaricons a {
    padding: 10px 8px 10px 16px;
    text-decoration: none;
    font-size: 14px;
    color: #fff;
    display: block;
}

.sidebaricons a:hover,
.sidebaricons .active {
    color: #e53535;
    background-color: #f9f9f9;
}

.sidebaricons .logo a:hover {
    background-color: #243a5e !important;
}

.sidebar {
    height: 100%;
    width: 190px;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: #243a5e;
    /* background-color: #f7f5fa; */
    overflow-x: hidden;
    padding-top: 6px;
}

  .sidebar .main-menu {
    width: 100%;
    text-align: left;
  }

  .sidebar a, .sidebar .main-menu {
    padding: 10px 8px 10px 16px;
    text-decoration: none;
    font-size: 14px;
    color: #fff;
    display: block;
  }

    .sidebar a:hover,
    .sidebar .active,
    .sidebar .main-menu :hover,
    .sidebar .main-menu .active {
      color: #e53535 !important;
      background-color: #f9f9f9 !important;
    }

.sidebar .logo a:hover {
    background-color: #243a5e !important;
}

.main {
    margin-left: 190px;
    /* Same as the width of the sidenav */
    padding: 0px 0px;
}

.maintwo {
    margin-left: 50px;
    /* Same as the width of the sidenav */
    padding: 0px 0px;
}

@media screen and (max-height: 450px) {
    .sidebar {
        /*padding-top: 15px;*/
    }
    .sidebar a {
        /*font-size: 18px;*/
    }
}

.row-group {
    padding-left: 10px !important;
}

.header-border-top {
    border-top: 5px solid var(--main-color);
}

.table-border {
    border-top: 5px solid var(--main-color) !important;
    border-bottom: 5px solid var(--main-color) !important;
}

.noborderbottom {
    border-bottom: none !important;
}

.pills-header {
    background-color: #f6f6f6;
    margin-left: 2px;
    padding: 12px 18px;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

app-main .nav-item .nav-link:hover, app-admin .nav-item .nav-link:hover {
  text-decoration: underline;
}

.admin .table thead,
.admin .table th {
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  padding: 14px 2px !important;
  font-weight: bolder;
  border-bottom: #dedede solid 3px !important;
}

.admin .table tr,
.admin .table td {
  padding: 10px !important;
  vertical-align: middle;
}

.admin .table-data-width {
    min-width: 2%;
    text-align: center;
}

.admin .table-data-width-actions {
    width: 120px;
    text-align: center;
}

.admin .table-width-side-heading {
    width: 22%;
}

.admin .table-td-md {
    width: 8%;
}

.admin .table thead tr:last-of-type {
    position: sticky;
    z-index: 8;
}

.bg-primary2 {
    background: var(--main-primary2);
}

.portret-title {
    background: var(--main-primary2);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.green.btn {
    color: #FFFFFF;
    background-color: #26a69a;
}

.green.btn:hover,
.green.btn:focus,
.green.btn:active,
.green.btn.active {
    color: #FFFFFF;
    background-color: #1f897f;
}

.page-item.active .page-link,
.page-item.active .page-link:hover {
    background: var(--main-primary2);
    border-color: var(--main-primary2);
}


/* new side bar code /
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }



  
/* Labels variants */

.admin .label {
    color: #ffffff;
    border-radius: 1%;
    padding: 3px 6px 3px 6px;
}

.label-default {
    background-color: #c6c6c6;
}

.label-default[href]:hover,
.label-default[href]:focus {
    background-color: #adadad;
}

.label-primary {
    background-color: #428bca;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
    background-color: #3071a9;
}

.label-success {
    background-color: #45B6AF;
}

.label-success[href]:hover,
.label-success[href]:focus {
    background-color: #37918b;
}

.label-info {
    background-color: #89C4F4;
}

.label-info[href]:hover,
.label-info[href]:focus {
    background-color: #5aadf0;
}

.label-warning,
.label-degradation {
  background-color: #dfba49;
}

  .label-warning[href]:hover,
  .label-warning[href]:focus,
  .label-degradation[href]:hover,
  .label-degradation[href]:focus {
    background-color: #d0a625;
  }




.label-danger {
  background-color: #F3565D;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
    background-color: #f0262f;
}

.btn.default {
    color: #333333;
    background-color: #E5E5E5;
}

.btn.green-stripe {
    border-left: 3px solid #26a69a;
}

.btn-xs {
    padding: 1px 5px;
    font-size: 13px;
    line-height: 1.5;
}

.btn-primary2 {
    color: #fff;
    background-color: #243a5e;
    border-color: #243a5e;
}

.btn-primary2:hover {
    color: #fff;
    background-color: #243a5e;
    border-color: #243a5e;
}

app-admin .sticky-menu, app-main .sticky-menu {
  position: sticky;
  top: 0;
  z-index: 10;
}

small {
    color: #565da5;
}

.danger {
    color: red;
}

.form-select,
.form-control {
    font-size: 14px;
}

.f16 {
    font-size: 16px;
}

app-admin .logo, app-main .logo {
  margin-top: 0.5rem;
}

#navbarSupportedContent .nav-link {
    text-align: right;
}

.rounded-pill-sm {
    border-radius: 0.3rem;
}

.custom-dropdown-menu {
    left:-100% !important;
}

.subscribe-btn {
    color: #fff !important;
    background-color: #0d6efd;
    border-color: #0d6efd;
    text-align:center !important;
}
/***/
 
.tooltip {
    position: relative;
    z-index: 100;
    border: 1px;
    padding: 3px;
}


.badge i {
    color:#fff !important;
}

/***/


/* loader code */

.loader {
  /* margin: 200px auto; */
  position: fixed;
  top: 45%;
  left: 45%;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 15px solid #eeeeee;
  border-top: 15px solid #f0262f;
  animation: spin 1.5s linear infinite;
  z-index:10000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* loader code */


/* Tooltip code */

/* Tooltip Bottom Right */
.itooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  font-size:10px;
}

  .itooltip span {
    visibility: hidden;
    /* width:100px; */
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: -70%;
    left: 60%;
    margin-left: 0px;
    margin-top: 20%;
    opacity: 0;
    transition: opacity 0.3s;
  }


  .itooltip:hover span {
    visibility: visible;
    opacity: 1;
  }

/* Tooltip Bottom Right */

/* Tooltip Mega Bottom */

.ibtooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

  .ibtooltip .data {
    visibility: hidden;
    /* width:100px; */
    font-size: 12px;
    background-color: #fff;
    color: #333;
    text-align: left;
    border-radius: 5px;
    padding: 20px;
    position: absolute;
    z-index: 1;
    bottom: -0%;
    left: -90%;
    margin-left: 0px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .ibtooltip:hover .data {
    visibility: visible;
    opacity: 1;
  }
/* Tooltip Mega Bottom */

/* Tooltip Right */

/* Tooltip Right */

/* Tooltip Code */

app-admin .sticky-footer, app-main .sticky-footer{
  bottom: 0;
}

.checkbox-label{
    margin: auto;vertical-align: middle;padding: 10px;
}

.checkbox-input{
    font-size: 24px;
}
.bg-green{
  background-color: #00850d;
}
.t-green{
  color: #00850d;
}

.operational-title-green{
  background-color: #00850d;
  padding: 15px;
  border-radius: 8px;
  /* box-shadow: 0px 20px 40px rgba(9,9,9,0.1); */
  margin-bottom: 2rem;
  color: #fff;
  text-align: center;
}
.operational-title{
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 20px 40px rgba(9,9,9,0.1);
  margin-bottom: 2.5rem;
  color: #00850d;
  text-align: center;
}

.operational-title b{
  color: #111;
}

.box-container{
  border-left: 3px solid #00850d;
  border-top: 1px solid #eee;
  border-right: 3px solid #00850d;
  border-bottom: 1px solid #eee;
  margin: 20px 0px;
}

.top-container{
  padding:10px 25px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 20px 40px rgba(9,9,9,0.1);
  margin-bottom: 2.5rem;
}
.top-container-counters{
  padding:25px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 20px 40px rgba(9,9,9,0.1);
  margin-bottom: 2.5rem;
}
.top-container-counters h4{
  font-weight: bold;
  color: #000;
}
.top-container-counters label{
  color: #bbb;
}
.top-container-counters .counter-container{
  border-right: 1px solid #ddd;
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent !important;
  outline: 0;
  /* box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25); */
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #fff;
  /* box-shadow: inset 0 -1px 0 rgba(0,0,0,.125); */
  box-shadow: none !important;
}

.accordion-flush .accordion-item .accordion-button{
  border-bottom: 1px solid #eee;
}

.accordion-body{
  background-color: #f8f9fa;
  padding: 5px 2px;
}
.accordion-body .body-content{
  border-bottom: 1px solid #eee;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}
.accordion-body .body-content:last-child{
  border-bottom: none !important;
  padding-bottom: 8px;
}
.accordion-body .body-content:first-child{
  padding-top: 8px;
}
/* modal.component.css */
@keyframes tableRowLoading {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}
/* Additional Styles for the Loading Animation */
.loading-row {
  animation: tableRowLoading 1s infinite;
}